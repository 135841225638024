import { useState, useEffect, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Select, MenuItem, FormControl, InputLabel, Box, IconButton } from '@mui/material';
import { CustomIcon, LinkButton, OutlinedButton, SubmitButton } from 'components/UIComponents';
import { StyledDialogActions as DialogActions } from 'components/base/BaseModal/styledDialogActions';
import { StyledDialogContent as DialogContent } from 'components/base/BaseModal/styledDialogContent';

import Modal from 'components/base/BaseModal';
import { LinkButtonText } from '../ActionsPanel/styledComponents';
import { colors } from 'styles/globalStyles';
import { FilterContext } from '../../../../context/FilterContext';
import { SpotType } from '../../../../constants/enums/SpotTypes';

const SORT_FORM_MODAL_NAME = 'sort_form_modal';
const MAX_SORTING = 2;

interface SortOptionsModalProps {
  onClose: () => void;
  type: SpotType;
}

const sortOptions = [
  { value: 'outlet_asc', label: 'Outlet (A-Z)' },
  { value: 'outlet_desc', label: 'Outlet (Z-A)' },
  { value: 'start_date_asc', label: 'Start Date (oldest-newest)' },
  { value: 'start_date_desc', label: 'Start Date (newest-oldest)' },
  { value: 'gross_rate', label: 'Gross Rate (lowest to highest)' },
  { value: 'day_of_week', label: 'Day of Week (Mon-Sun)' },
];

const SortOptionsModal = ({ onClose, type }: SortOptionsModalProps) => {
  const [sortFields, setSortFields] = useState<string[]>(['outlet_asc']);
  const { filterOptions, onFilter } = useContext(FilterContext);
  const [searchParams] = useSearchParams();

  const filteredSortOptions = sortOptions.filter((option) => {
    if (type === SpotType.legacy) {
      return true;
    }
    return option.value !== 'gross_rate' && option.value !== 'day_of_week';
  });

  useEffect(() => {
    const filterParam = searchParams.get('filter') ? JSON.parse(searchParams.get('filter')!) : null;
    const sorts: string[] = filterParam?.sort || [];
    setSortFields(sorts.length > 0 ? sorts : ['outlet_asc']);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddSort = () => {
    if (sortFields.length < MAX_SORTING) {
      const disabledOptions = sortFields.flatMap((field) => {
        const relatedDisabled = [];
        if (field === 'outlet_asc') relatedDisabled.push('outlet_desc');
        if (field === 'outlet_desc') relatedDisabled.push('outlet_asc');
        if (field === 'start_date_asc') relatedDisabled.push('start_date_desc');
        if (field === 'start_date_desc') relatedDisabled.push('start_date_asc');
        return [field, ...relatedDisabled];
      });

      const availableOptions = filteredSortOptions
        .map((opt) => opt.value)
        .filter((value) => !disabledOptions.includes(value));

      const newField = availableOptions[0] || '';
      if (newField) {
        setSortFields([...sortFields, newField]);
      }
    }
  };

  const handleRemoveSort = (index: number) => {
    setSortFields((prevSortFields) => prevSortFields.filter((_, i) => i !== index));
  };

  const handleChangeSort = (index: number, value: string) => {
    setSortFields((prevSortFields) => {
      const updatedFields = [...prevSortFields];
      updatedFields[index] = value;
      return updatedFields;
    });
  };

  const handleSave = () => {
    onClose();
    onFilter({ ...filterOptions, sort: sortFields });
  };

  const getDisabledOptions = (currentIndex: number): string[] => {
    const disabledOptions: string[] = [];

    sortFields.forEach((field, index) => {
      if (index !== currentIndex) {
        disabledOptions.push(field);

        if (field === 'outlet_asc') disabledOptions.push('outlet_desc');
        if (field === 'outlet_desc') disabledOptions.push('outlet_asc');
        if (field === 'start_date_asc') disabledOptions.push('start_date_desc');
        if (field === 'start_date_desc') disabledOptions.push('start_date_asc');
      }
    });

    return disabledOptions;
  };

  return (
    <Modal name={SORT_FORM_MODAL_NAME} title="Sort Options" onModalClose={onClose}>
      <DialogContent>
        {sortFields.map((field, index) => (
          <Box key={index} display="flex" alignItems="center" marginBottom={2}>
            <Box style={{ minWidth: 110, marginLeft: 10 }}>
              <InputLabel>{index === 0 ? 'Sort by' : 'Then sort by'}</InputLabel>
            </Box>
            <FormControl style={{ width: '70%' }}>
              <Select
                value={field}
                style={{height: 40}}
                onChange={(e) => handleChangeSort(index, e.target.value)}
              >
                {filteredSortOptions.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    disabled={getDisabledOptions(index).includes(option.value) && sortFields[index] !== option.value}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {index > 0 && (
              <IconButton
                color="error"
                onClick={() => handleRemoveSort(index)}
                aria-label="Remove sort"
                style={{ marginLeft: 8 }}
              >
                <CustomIcon size="normal" name="trash" color={colors.black} />
              </IconButton>
            )}
          </Box>
        ))}
        <LinkButton onClick={handleAddSort} disabled={sortFields.length >= MAX_SORTING}>
          <LinkButtonText>Add New Sort</LinkButtonText>
        </LinkButton>
      </DialogContent>
      <DialogActions>
        <OutlinedButton onClick={onClose}>Cancel</OutlinedButton>
        <SubmitButton type="submit" onClick={handleSave}>Save</SubmitButton>
      </DialogActions>
    </Modal>
  );
};

export default SortOptionsModal;